import state from './moduleMealManagementState.js'
import mutations from './moduleMealManagementMutations.js'
import actions from './moduleMealManagementActions.js'
import getters from './moduleMealManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

