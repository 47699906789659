import axios from '@/axios.js'

export default {
  fetchMeals ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`meal-types`)
        .then(({data: response}) => {
          commit('SET_MEALS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchMeal (context, mealId) {
    return new Promise((resolve, reject) => {
      axios.get(`meal/${mealId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeMeal (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`meal-types`, payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateMeal (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`meal/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyMeal ({ commit }, mealId) {
    return new Promise((resolve, reject) => {
      axios.delete(`meal/${mealId}`)
        .then((response) => {
          commit('REMOVE_RECORD', mealId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
